import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { store } from '../context/store';

const Economist = () => {
  const globalState = useContext(store);
  const { todaysNewsList } = globalState.state;

  useEffect(() => {
    if (todaysNewsList.length) {
      navigate(`/article?page=${todaysNewsList[0].link}`);
    }
  }, [todaysNewsList]);

  return (
    <Layout>
      <SEO title="Home" />
    </Layout>
  );
}

export default Economist;
